<template>
  <div>
    <div class="wrap-system">
      <div class="system-1">
        <div class="d-flex align-items-center justify-content-center flex-column">
          <img class="w-25 bounce-top" src="@/static/image/pve-1.png" alt="" />
          <h2 class="font-weight-bold text-white tracking-in-expand">
            PVE系统
          </h2>
          <img class="py-3" src="@/static/image/pve-2.png" alt="" />
          <div class="text-system">
            <p>
              中壬云的研发初心紧贴现代中小微企业风控难点、盲点，可替代大部分企业信贷业务中繁琐的人工实操等环节，仅需几分钟即可在线完成企业债权测评并取得企业信贷价值报告，信贷价值报告涵盖企业实际经营的各方面数据，真实反映企业经营状况并且掌握企业现金流情况。
            </p>
            <p>
              中壬云债权评测系统依托原创PVE算力模型，融合多种算法，使用者仅需提供3份表：财报、流水以及纳税数值，通过对资料中的数据勾兑比例取值、交叉核验各项数据、自动剔除关联交易虚假流水等来鉴别营收、成本、周转、利润数据真伪，更加深层次地完成流水反欺诈操作。
            </p>
            <p>
              根据不同使用机构的个性化需求，中壬云债权评测系统输出了可私有化部署的软件版和标准化SaaS平台两种服务版本。软件版可根据金融机构的风控准则设定标准，支持多种方式接入，而SaaS平台不受场地和空间限制，云端储存，可多终端灵活切换。
              目前，中壬云债权评测系统后台计算次数高于行业平均水平数十倍，已累计获取风控类软著20余项，系统服务于多家金融机构，累计评测次数超过2万余次，系统应用场景综合逾期率控制在0.89%以内，为合作伙伴严守风控生命线。
            </p>
          </div>
          <img class="py-3 pulsate-bck" src="@/static/image/pve-3.png" alt="" />
        </div>
      </div>
      <div class="system-2 d-flex justify-content-center align-items-center">
        <div class="system-2-content-left w-50 position-relative">
          <img class="w-100 img open-img" src="@/static/image/pve-4.png" alt="" />
          <div class="system-content">
            <h3 class="system-content-title">开放式版本</h3>
            <div class="system-content-text">
              提供SaaS化开放式智能风控系统，满足个人、企业和机构更为灵活、短期、低预算、使用便捷的信贷风控业务需求。
            </div>
          </div>
        </div>
        <div class="w-50 system-2-content-right">
          <div class="right-item d-flex justify-content-center align-items-center" v-for="(item, index) in OpenItems"
               :key="index" @mouseenter="enter({ index: index, str: 'OpenItems' })"
               @mouseleave="leave({ index: index, str: 'OpenItems' })">
            <div class="title-left d-flex justify-content-center align-items-center flex-column">
              <img class="title-img" :src="item.src" alt="" />
              <h5 class="title">{{ item.title }}</h5>
            </div>
            <div class="text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <img src="@/static/image/pve-2.png" alt="" />
      </div>

      <div class="system-3 system-2 d-flex justify-content-center align-items-center">
        <div class="w-50 system-2-content-right">
          <div class="right-item d-flex justify-content-center align-items-center" v-for="(item, index) in softwareItem"
               :key="index" @mouseenter="enter({ index: index, str: 'softwareItem' })"
               @mouseleave="leave({ index: index, str: 'softwareItem' })">
            <div class="text">
              {{ item.text }}
              <a v-if="index === 0"  href="https://www.cnskbb.com/openApi" target="_blank">
                <button
                  class="text-center border-0 rounded-pill border-dark small text-white bg-pink px-4 py-1 mt-1 ml-auto" style="line-height: 2">
                  立即体验
                </button>
              </a>
            </div>

            <div class="title-left d-flex justify-content-center align-items-center flex-column">
              <img class="title-img" :src="item.src" alt="" />
              <h5 class="title">{{ item.title }}</h5>
            </div>
          </div>
        </div>
        <div class="system-2-content-left w-50 position-relative">
          <img class="w-100 img open-img" src="@/static/image/pve-6.png" alt="" />
          <div class="system-content">
            <h3 class="system-content-title">软件版本</h3>
            <div class="system-content-text">
              提供包括个性化定制、私有化部署等功能在内的软件版本的智能风控系统，满足机构更为高级、复杂、私密的信贷风控业务需求。
            </div>

            <button @click="linkLoad"
                    class=" text-center  dy-btn border-0 rounded-pill border-dark small text-white bg-pink px-4 py-2 mt-1 ml-auto">
              进入下载
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
  import Footer from "@/components/footer.vue";

  export default {
    components: {
      Footer,
    },
    data() {
      return {
        entryLeave: false,
        OpenItems: [
          {
            title: "财报初评",
            text:
              "运用PVE核心算力勾兑企业息税前营业利润、息税前利润率、存 货周转率、应收账款周转率、预收账款周转天数、应付账款周转 天数等约425类指标，得出企业60-90天内现金流安全区间值。",
            src: require("@/static/image/pve-5.png"),
          },
          {
            title: "流水反欺诈",
            text:
              "运用PVE核心算力勾兑企业息税前营业利润、息税前利润率、存 货周转率、应收账款周转率、预收账款周转天数、应付账款周转 天数等约425类指标，得出企业60-90天内现金流安全区间值。",
            src: require("@/static/image/pve-11.png"),
          },
          {
            title: "信贷报告生成",
            text:
              "将财报、流水、纳税数值勾兑比例取值，并整合企业工商、司法等信息，通过PVE算力模型，融入多种前沿算法交叉核验，从不同新式维度精细分析各项数据真伪和影响，自动生成信贷价值报告。帮助机构掌握客户企业经营实况。",
            src: require("@/static/image/pve-12.png"),
          },
        ],
        softwareItem: [
          {
            title: "本地化部署",
            text:
              "根据企业需要，提供本地化部署，将数据储存在本地，杜绝第三方获取，同时可本地开发，灵活扩展各种功能应用，满足机构的多样化需求。",
            src: require("@/static/image/pve-13.png"),
          },
          {
            title: "API接口",
            text:
              "通过API接口，可快速便捷地链接其他软件或程序，提升产品应用的广泛性和灵活扩展性。",
            src: require("@/static/image/pve-14.png"),
          },
          {
            title: "数据私密",
            text: "数据存储于企业本地服务器，稳定性和安全性更有保障。",
            src: require("@/static/image/pve-15.png"),
          },
          {
            title: "个性化进件标准设定",
            text:
              "根据机构个性化需求，定制更符合机构业务特点的系统功能，最大化提升企业效率。",
            src: require("@/static/image/pve-16.png"),
          },
        ],
      };
    },
    methods: {
      // 鼠标移入
      enter(obj) {
        console.log("移入获得的对象", obj);
        let index = obj.index;
        if (obj.str == "OpenItems") {
          switch (index) {
            case 0:
              this.amendImage(obj, "pve-5-sel");
              break;
            case 1:
              this.amendImage(obj, "pve-11-sel");
              break;
            case 2:
              this.amendImage(obj, "pve-12-sel");
              break;
            default:
              break;
          }
        }
        if (obj.str == "softwareItem") {
          switch (index) {
            case 0:
              this.amendImage(obj, "pve-13-sel");
              break;
            case 1:
              this.amendImage(obj, "pve-14-sel");
              break;
            case 2:
              this.amendImage(obj, "pve-15-sel");
              break;
            case 3:
              this.amendImage(obj, "pve-16-sel");
              break;
            default:
              break;
          }
        }
      },
      // 鼠标移除
      leave(obj) {
        let index = obj.index;
        if (obj.str == "OpenItems") {
          switch (index) {
            case 0:
              this.amendImage(obj, "pve-5");
              break;
            case 1:
              this.amendImage(obj, "pve-11");
              break;
            case 2:
              this.amendImage(obj, "pve-12");
              break;
            default:
              break;
          }
          return;
        }
        if (obj.str == "softwareItem") {
          switch (index) {
            case 0:
              this.amendImage(obj, "pve-13");
              break;
            case 1:
              this.amendImage(obj, "pve-14");
              break;
            case 2:
              this.amendImage(obj, "pve-15");
              break;
            case 3:
              this.amendImage(obj, "pve-16");
              break;
            default:
              break;
          }
          return;
        }
      },
      // 滑入修改圖片
      amendImage(obj, imgName) {
        let index = obj.index;
        let type = obj.str;
        console.log("------", this[type]);
        return this.$set(
          this[type][index],
          `src`,
          require(`@/static/image/${imgName}.png`)
        );
      },
      // 软件下载
      linkLoad() {
        this.$router.push({ name: "download" });
      },
    },
  };
</script>

<style lang="less" scoped>
  .wrap-system {
    padding: 0 12rem;
    color: #6f76b0;

    .system-1 {
      margin-top: 60px;

      .text-system {
        line-height: 2;
        font-size: 18px;
        text-indent: 2em;
        text-align: justify;
      }
    }

    width: 100%;

    .system-2 {
      margin-top: 40px;

      .system-2-content-left {
        position: relative;
        text-align: left;

        img {
          width: 100%;
          height: 100%;
        }

        .open-img {
          // transform: rotate(-90deg);
          transition: transform 0.5s linear;
        }

        &:hover .open-img {
          // transform: rotate(0deg);
        }

        .system-content {
          position: absolute;
          right: 40%;
          top: 50%;
          transform: translate(50%, -50%);

          .system-content-title {
            color: #808cec;
          }

          .system-content-text {
            line-height: 2;
            font-size: 18px;
          }
        }
      }

      .system-2-content-right {
        padding-left: 45px;
        width: 150px;

        .right-item {
          &:nth-child(2) {
            margin-left: 120px;
          }

          margin-bottom: 40px;

          .title-left {
            width: 20%;

            .title {
              color: #808cec;
            }

            .title-img {
              width: 90px;
              margin-bottom: 10px;
            }
          }

          .text {
            flex: 1;
            padding-left: 15px;
            justify-content: center;
            line-height: 2;
            font-size: 18px;
            // text-indent: 2em;
            text-align: justify;
          }
        }
      }
    }

    .system-3 {
      .system-2-content-left {
        text-align: right;

        .system-content {
          position: absolute;
          right: 40%;
          top: 50%;
          transform: translate(10%, -50%);

          .system-content-title {
            color: #808cec;
          }

          .system-content-text {
            line-height: 2;
            font-size: 18px;
          }
        }
      }

      .system-2-content-right {
        .right-item {
          &:nth-child(2),
          &:nth-child(3) {
            margin-right: 120px;
            margin-left: 0px;
          }

          margin-bottom: 40px;

          .title-left {
            width: 20%;

            .title {
              color: #808cec;
            }

            .title-img {
              width: 90px;
              margin-bottom: 10px;
            }
          }

          .text {
            text-indent: 0em;
            text-align: left;
          }
        }
      }
    }

    @keyframes wobble-hor-bottom {
      0%,
      100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
      }
      15% {
        transform: translateX(-30px) rotate(-6deg);
      }
      30% {
        transform: translateX(15px) rotate(6deg);
      }
      45% {
        transform: translateX(-15px) rotate(-3.6deg);
      }
      60% {
        transform: translateX(9px) rotate(2.4deg);
      }
      75% {
        transform: translateX(-6px) rotate(-1.2deg);
      }
    }

    // 文字出现
    .tracking-in-expand {
      animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    }

    @keyframes tracking-in-expand {
      0% {
        letter-spacing: -0.5em;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }

    // 箭头
    .pulsate-bck {
      animation: pulsate-bck 0.5s ease-in-out infinite both;
    }

    @keyframes pulsate-bck {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }
    }

    // 最上边
    .bounce-top {
      animation: bounce-top 0.9s;
    }

    @keyframes bounce-top {
      0% {
        transform: translateY(-45px);
        animation-timing-function: ease-in;
        opacity: 1;
      }
      24% {
        opacity: 1;
      }
      40% {
        transform: translateY(-24px);
        animation-timing-function: ease-in;
      }
      65% {
        transform: translateY(-12px);
        animation-timing-function: ease-in;
      }
      82% {
        transform: translateY(-6px);
        animation-timing-function: ease-in;
      }
      93% {
        transform: translateY(-4px);
        animation-timing-function: ease-in;
      }
      25%,
      55%,
      75%,
      87% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
      }
      100% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
        opacity: 1;
      }
    }
  }
</style>
